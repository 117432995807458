<template>
  <div class="page">
    <div class="topback">
      <span class="curse" @click="goBack">{{$t('N_I_241')}}</span>
    </div>
    <div class="topbox">
      <div class="toptitle">{{ detailData.title }}</div>
      <div class="topsmall">
        <div class="small_1">{{ detailData.functionTypeVal }}</div>
        <div>{{ detailData.creationTime }}</div>
      </div>
    </div>
    <div class="detail_content" v-html="detailData.content"></div>
  </div>
</template>

<script>
import * as sid from "@/api/sidebaruserinfo.js";
export default {
  name: "NoticeDetail",
  data() {
    return {
      pkId: "",
      functionType: "",
      detailData: {},
    };
  },
  mounted() {
    this.pkId = this.$route.query.pkId;
    this.functionType = this.$route.query.functionType;
    this.getDetail();
  },
  methods: {
    getDetail() {
      sid
        .getNoticeDetail({ pkId: this.pkId, functionType: this.functionType })
        .then((res) => {
          this.detailData = res.data;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .detail_content img {
  max-width: 100%!important;
  height: auto;
  
}
.page {
  background: #f9f9f9;
  .topback {
    margin-top: 20px;
    box-shadow: 0px 2px 20px 0px rgba(203, 203, 203, 0.5);
    // width: 100%;
    background: #ffffff;
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    padding: 10px 60px;
    .curse {
      cursor: pointer;
    }
  }
  .topbox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .toptitle {
    // width: ;
    margin-top: 30px;
    font-size: 32px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
  .topsmall {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    margin-top: 20px;
    .small_1 {
      margin-right: 80px;
    }
  }
  .detail_content {
    margin: auto;
    width: 1800px;
    min-height: 500px;
    background: #ffffff;
    box-shadow: 0px 2px 20px 0px #ededed;
    border-radius: 10px 10px 10px 10px;
    margin-top: 20px;
    padding: 20px;
    font-size: 14px;
    overflow: auto;
    text-align: center;
  }
}
</style>