
import request from '@/util/request'

// 公告站内信列表 公告1站内信2
export function getNoticeList(params) {
    return request({
        url: '/system/api/notice/index-list',
        method: 'get',
        params
    })
}
// 创客空间列表
export function marketList(params) {
    return request({
        url: '/member/api/maker-space/list',
        method: 'get',
        params
    })
}

//意见反馈类型
export function getFeedbackType(params) {
    return request({
        url: '/system/pub/enums/feedback-type',
        method: 'get',
        params
    })
}

//提交意见反馈列表
export function saveFeedBack(data) {
  return request({
    url: '/system/api/feedback/save',
    method: 'post',
    data
  })
}

//意见列表
export function getFeedbackList(params) {
    return request({
        url: '/system/api/feedback/index-list',
        method: 'get',
        params
    })
}
//荣誉墙
export function getMemberHonorWall(params) {
    return request({
        url: '/member/api/member/member-honor-wall',
        method: 'get',
        params
    })
}
//全部荣誉墙
export function getMemberHonorWallAll(params) {
    return request({
        url: '/member/api/member/member-honor-wall-all',
        method: 'get',
        params
    })
}
//获取会员真实奖衔
export function getUserAwards(params) {
    return request({
        url: '/member/api/member/index-member-awards',
        method: 'get',
        params
    })
}

//获取公告、站内信详情
export function getNoticeDetail(params) {
    return request({
        url: '/system/api/notice/index-detail',
        method: 'get',
        params
    })
}

// 我的市场点亮
export function getMyMarketColor(params) {
    return request({
        url: '/system/manage/area/get-light-area',
        method: 'get',
        params
    })
}

//公告站内信意见反馈数量
export function getNoticeCount(params) {
    return request({
        url: '/system/api/notice/index-count',
        method: 'get',
        params
    })
}
//会员奖衔信息
export function memberAwards(params) {
    return request({
        url: '/member/api/member/member-awards-info',
        method: 'get',
        params
    })
}